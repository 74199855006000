/* Define background color and general styles */
@keyframes backgroundGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.app {
  background: linear-gradient(
    -45deg,
    #4a1d96,
    #7e22ce,
    #be185d,
    #4a1d96,
    #7e22ce
  );
  background-size: 400% 400%;
  animation: backgroundGradient 15s ease infinite;
  color: white;
  font-family: Arial, sans-serif;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Style the white content area */
.content-area {
  background-color: #111827;
  width: 100%;
  min-height: 70vh;
  margin-top: 20px;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  color: white;
  z-index: 1;
  position: relative;
  overflow-x: hidden;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-size: 13px;
}

.nav-button {
  background-color: rgba(6, 78, 59, 0.5);
  border: none;
  color: white;
  padding: clamp(6px, 2vw, 8px) clamp(12px, 3vw, 16px);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: clamp(14px, 3.5vw, 16px);
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  white-space: nowrap;
  min-width: fit-content;
}

.nav-button:hover {
  background-color: rgba(168, 85, 247, 0.7); 
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.start-new-coin {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.start-new-image {
  width: 180px;
}

.main-card {
  margin-bottom: 10px;
  margin-top: none;
}

.card-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.card-list > * {
  width: 100%;
  max-width: 500px;
}

/* Existing styles... */

/* Add responsive styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    gap: 15px;
  }

  .nav-right {
    flex-direction: column;
    width: 100%;
  }

  .nav-buttons, .wallet-buttons {
    width: 100%;
    justify-content: center;
  }

  .nav-button {
    flex: 1;
    max-width: 200px;
  }
}

/* Add styles to prevent horizontal scrolling */
.App {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Add these new styles */
@keyframes borderGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-border {
  position: relative;
  border-radius: 12px;
  z-index: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1)!important;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5)!important;
  height: 280px;
  perspective: 1000px;
  overflow: hidden;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  text-align: center;
  border-radius: 20px;
  background: rgba(47, 32, 143, 0.175);
}

.animated-border::before {
  content: '';
  position: absolute;
  inset: -3px;
  background: linear-gradient(
    45deg,
    #9333ea,
    #a955f7c4,
    #ec489aa9,
    #9333ea
  );
  border-radius: 15px;
  z-index: -1;
  background-size: 300% 300%;
  animation: borderGradient 5s ease infinite;
  padding: 3px;
}

.card-front,
.card-back {
  position: absolute;
  inset: 0;
  backface-visibility: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(17, 24, 39, 0.25);
  gap: 10px;
}

.card-back {
  transform: rotateY(180deg);
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.animated-border:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front h3,
.card-back h3 {
  font-size: clamp(13px, 3.5vw, 15px);
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-front p,
.card-back p {
  font-size: clamp(11px, 3vw, 13px);
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

/* Add animation delay for each card */
.fade-in-up:nth-child(1) { animation-delay: 0.1s; }
.fade-in-up:nth-child(2) { animation-delay: 0.2s; }
.fade-in-up:nth-child(3) { animation-delay: 0.3s; }
.fade-in-up:nth-child(4) { animation-delay: 0.4s; }
.fade-in-up:nth-child(5) { animation-delay: 0.5s; }
.fade-in-up:nth-child(6) { animation-delay: 0.6s; }
.fade-in-up:nth-child(7) { animation-delay: 0.7s; }
.fade-in-up:nth-child(8) { animation-delay: 0.8s; }
.fade-in-up:nth-child(n+9) { animation-delay: 0.9s; }

/* Update the content container in Home component */
.content-container {
  background-color: rgba(0, 0, 0, 0.9);
  padding: clamp(10px, 3vw, 20px);
  border-radius: 12px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(236, 72, 153, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(236, 72, 153, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(236, 72, 153, 0);
  }
}

.hot-token {
  animation: pulse 2s infinite;
}

/* Add this to your existing styles */
.token-grid {
  display: grid;
  grid-template-columns: repeat(3, 240px);
  gap: 20px;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0px;
}

.token-container {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 95%;
  max-width: 2400px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .token-grid {
    grid-template-columns: repeat(2, 240px);
  }
}

@media (max-width: 768px) {
  .token-container {
    width: 98%;
    padding: 12px;
  }

  .token-grid {
    grid-template-columns: 240px;
    gap: 16px;
    padding: 12px;
  }
}

/* Update search styles */
.search-container {
  max-width: 600px;
  margin: 0 auto 32px;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 16px 24px;
  padding-right: 48px; /* Space for the icon */
  font-size: 1rem;
  color: white;
  background: rgba(147, 51, 234, 0.1);
  border: 2px solid rgba(147, 51, 234, 0.3);
  border-radius: 12px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
}

.search-input:focus {
  outline: none;
  border-color: rgba(147, 51, 234, 0.6);
  box-shadow: 0 0 20px rgba(147, 51, 234, 0.2);
  transform: translateY(-2px);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.search-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  opacity: 0.7;
  pointer-events: none;
}

/* Add animation for search results */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.token-grid > * {
  animation: fadeIn 0.3s ease-out forwards;
}

/* Add some hover effects */
.search-container:hover .search-input {
  border-color: rgba(147, 51, 234, 0.4);
}

.search-input:focus + .search-icon {
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
}

@media (max-width: 768px) {
  .token-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  .search-input {
    font-size: 0.9rem;
    padding: 12px 20px;
  }
}

/* Token Creation Styles */
.token-creation {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(26, 26, 26, 0.9);
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.token-creation-title {
  text-align: center;
  margin-bottom: 30px;
  color: white;
  font-size: 2rem;
}

.creation-fee {
  text-align: center;
  color: #a78bfa;
  font-size: 1.1rem;
  margin-bottom: 30px;
  padding: 10px;
  background: rgba(147, 51, 234, 0.1);
  border-radius: 10px;
}

.input-group {
  margin-bottom: 24px;
}

.input-label {
  display: block;
  color: #e5e7eb;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.input-help {
  color: #9ca3af;
  font-size: 0.8rem;
  margin-top: 4px;
}

.token-input {
  width: 100%;
  padding: 12px 16px;
  background: rgba(17, 24, 39, 0.7);
  border: 2px solid rgba(147, 51, 234, 0.3);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.token-input:focus {
  outline: none;
  border-color: rgba(147, 51, 234, 0.6);
  box-shadow: 0 0 20px rgba(147, 51, 234, 0.2);
}

.upload-button {
  width: 100%;
  padding: 12px;
  background: rgba(147, 51, 234, 0.2);
  border: 2px dashed rgba(147, 51, 234, 0.4);
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.upload-button:hover {
  background: rgba(147, 51, 234, 0.3);
  border-color: rgba(147, 51, 234, 0.6);
}

.create-button {
  width: 100%;
  padding: 14px;
  background: linear-gradient(45deg, #9333ea, #c084fc);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.create-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(147, 51, 234, 0.4);
}

.create-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Token Page Styles */
.token-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.token-header {
  display: flex;
  align-items: center;
  gap: 24px;
  background: rgba(26, 26, 26, 0.9);
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.token-image {
  width: 120px;
  height: 120px;
  border-radius: 12px;
  object-fit: cover;
  border: 2px solid rgba(147, 51, 234, 0.3);
}

.token-info {
  flex: 1;
}

.token-title {
  font-size: 2rem;
  color: white;
  margin-bottom: 12px;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.token-detail {
  color: white;
  font-size: 0.9rem;
  margin-bottom: 8px;
  word-break: break-all;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.token-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.stat-card {
  background: rgba(26, 26, 26, 0.9);
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(147, 51, 234, 0.3);
}

.stat-label {
  color: white;
  font-size: 0.9rem;
  margin-bottom: 4px;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.stat-value {
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

/* Chart Container */
.chart-container {
  background: rgba(26, 26, 26, 0.9);
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.timeframe-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.timeframe-button {
  background: rgba(147, 51, 234, 0.2);
  border: 1px solid rgba(147, 51, 234, 0.3);
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.timeframe-button:hover {
  background: rgba(147, 51, 234, 0.3);
}

.timeframe-button.active {
  background: rgba(147, 51, 234, 0.4);
  border-color: rgba(147, 51, 234, 0.6);
}

